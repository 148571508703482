import {
    ExpenseInterface,
    monthNames,
    paymentTerms,
    IncomeInterface
} from './finance.constants';

// enum FinanceInterface {
//     ExpenseInterface,
//     IncomeInterface
// }

export function addDays(date, numDays) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + numDays);
    return newDate;
}

export function roundExpenseNumbers(object: any, fixedNum = 2): any {
    let newObj = {...object};
    Object.keys(object).forEach(function(key) {
        if (typeof object[key] === 'number' && object[key] % 1 !== 0) {
            newObj[key] = +object[key].toFixed(fixedNum)
        }
    });
    return newObj;
}

export function roundExpenseArrayNumbers(array: any, fixedNum = 2): any {
    return array.map(object => roundExpenseNumbers(object, fixedNum));
}

export function getInitializedExpenseSelection(expenses: any): {} {
    const ids = expenses.map(expense => expense._id);
    let expenseSelection = {}
    ids.forEach(id => {
        expenseSelection[id] = false;
    })
    return expenseSelection;
}

export const capitalizeWords = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());


// FinanceDetailComponent Form Helpers:

export const getMonthName = (monthNum) => monthNames[monthNum - 1];

export const getPaymentTerms = key => paymentTerms[key];

