export enum ExpenseTypes {
    FUEL = 'fuel', 
    INSTRUCTOR = 'instructor', 
    MAINTENANCE = 'maintenance'
};

export enum IncomeTypes {
    TRAINEE = 'trainee'
}

export interface FuelExpense {
    _id: string
    aircraft: string
    cost: number
    gallons: number
    balance: number
    payments: Array<number>
    paid?: string | Date
}

export interface MaintenanceExpense {
    _id: string
    aircraft: string
    cost: number
    maintenance: number
    balance: number
    paid?: string | Date
}

export interface InstructorExpense {
    _id: string
    instructor: string
    cost: number
    hours: number
    balance: number
    paid?: string | Date
}

export interface TraineeIncome {
    _id: string
    trainee: string
    cost: number
    hours: number
    balance: number
    paid?: string | Date
}

export interface ExpenseInterface extends 
MaintenanceExpense, 
FuelExpense, 
InstructorExpense {};

export interface IncomeInterface extends TraineeIncome {};


export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export enum Terms {
    CONSIGNMENT = 'Consignment',
    DUE_ON_RECEIPT = 'Due on receipt',
    NET_15 = 'Net 15',
    NET_30 = 'Net 30',
    NET_60 = 'Net 60'
};

export function addDays(date: Date, numDays: number): Date {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + numDays);
    return newDate;
}

export const paymentTerms = {
    CONSIGNMENT: {
        display: 'Consignment',
        getDate: () => null,
        dueDateIsKnown: false
    },
    DUE_ON_RECEIPT: {
        display: 'Due on reciept',
        getDate: () => null,
        dueDateIsKnown: false
    },
    NET_15: {
        display: 'Net 15',
        getDate: (billDate: Date = new Date()): Date => addDays(billDate, 15),
        dueDateIsKnown: true

    },
    NET_30: {
        display: 'Net 30',
        getDate: (billDate: Date = new Date()): Date => addDays(billDate, 30),
        dueDateIsKnown: true
    },
    NET_60: {
        display: 'Net 60',
        getDate: (billDate: Date = new Date()): Date => addDays(billDate, 60),
        dueDateIsKnown: true
    }
}

